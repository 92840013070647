@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// "homepage": "https://ngf93.github.io/mkgroup",
:root {
  --h-top: 70px;
  --h-middle: 110px;
  --h-bottom: 70px;
  --h-height: calc(var(--h-top) + var(--h-middle) + var(--h-bottom));
  --f-height: 435px;
  --img-height: 300px;
  --img-height-4: 400px;
  --font-size: 24px;
  --color-1: #4737FF;
  @media (max-width: 1399px) {
    --h-top: 60px;
    --h-middle: 100px;
    --h-bottom: 60px;
    --f-height: 380px;
    --img-height: 260px;
    --img-height-4: 340px;
    --font-size: 20px;
  }
  @media (max-width: 1199px) {
    --h-top: 60px;
    --h-middle: 90px;
    --h-bottom: 60px;
    --f-height: 350px;
    --img-height: 215px;
    --img-height-4: 280px;
    --font-size: 18px;
  }
  @media (max-width: 991px) {
    --img-height-4: 335px;
    --h-height: 51px;
    --f-height: 77px;
  }
  @media (max-width: 767px) {
    --img-height-4: 245px;
    --font-size: 16px;
  }
  @media (max-width: 575px) {
    --img-height-4: 43vw;
    --img-height: 43vw;
  }
}

$main-font: "Inter";
$color-1: #4737FF;
$color-1-dark: #1C119C;
$color-2: #F75C03;
$color-2-dark: #FF3A0F;
$color-3: #668CB2;
$gradient-1: linear-gradient(135.19deg, $color-1 2.39%, $color-1-dark 97.31%);
$gradient-2: linear-gradient(90.6deg, $color-2 1.13%, $color-2-dark 98.66%);
$achromat-1: #FFFFFF;
$achromat-2: #F2F2F2;
$achromat-3: #D0D0D0;
$achromat-4: #7B7B7B;
$achromat-5: #1F1F1F;
$transition: 300ms ease;
$radius: .8em;

/* mixins */
@mixin flex($dir, $x, $y, $wrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $x;
  align-items: $y;
  flex-wrap: $wrap;
}
@mixin no-appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}
@mixin safariFix {
  isolation: isolate;
  transform: translateZ(0);
}
@mixin absBG {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@mixin clamp($count, $line) {
  display: -webkit-box;
  line-clamp: $count;
  -webkit-line-clamp: $count;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line;
  max-height: $line * $count;
}
@mixin list {
  list-style: none;
  padding-left: 0px;
}
@mixin shadow {
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.15));
}
@mixin shadow2 {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
@mixin shadow3 {
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.03));
}

/* Typography */
a, a:hover, a:focus, a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
h1, h2, h3, h4, h5, h6, p, label, span, a, button, form, fieldset, legend, select, input, textarea, button, div, blockquote, cite, figure, figcaption, table, ul, ol, dl, address {
  line-height: 120%;
  margin-bottom: 0px;
  @media (max-width:575px) {
    line-height: 120%;
  }
}

h1, h2 {
  text-align: left;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 1em;
  @media (max-width:575px) {
    font-size: 1.25em;
  }
}
h3 {
  text-align: left;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0px;
  @media (max-width: 575px) {
    font-size: 1.1em;
  }
}
h4 {
  text-align: left;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0px;
  @media (max-width: 575px) {
    font-size: 1em;
  }
}
h5 {
  text-align: left;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 1em;
  @media (max-width: 575px) {
    font-size: .9em;
  }
}
h6 {
  font-size: .75em;
  font-weight: 400;
  margin-bottom: 0px;
}

.fs {
  &-07 {font-size: 0.7em;}
  &-075 {font-size: 0.75em;}
  &-08 {font-size: 0.8em;}
  &-09 {font-size: 0.9em;}
  &-10 {font-size: 1em;}
  &-11 {font-size: 1.1em;}
  &-12 {font-size: 1.2em;}
  &-13 {font-size: 1.3em;}
  &-14 {font-size: 1.4em;}
  &-15 {font-size: 1.5em;}
  &-16 {font-size: 1.6em;}
  &-17 {font-size: 1.7em;}
  &-18 {font-size: 1.8em;}
  &-19 {font-size: 1.9em;}
  &-20 {font-size: 2em;}
  &-25 {font-size: 2.5em;}
  &-30 {font-size: 3em;}
  &-35 {font-size: 3.5em;}
  &-40 {font-size: 4em;}
}
.fw {
  &-3 {font-weight: 300;}
  &-4 {font-weight: 400;}
  &-5 {font-weight: 500;}
  &-6 {font-weight: 600;}
  &-7 {font-weight: 700;}
  &-8 {font-weight: 800;}
  &-9 {font-weight: 900;}
}
.black {color: #000;}
.color-1 {color: $color-1;}
.color-2 {color: $color-2;}
.achromat-4 {color: $achromat-4;}
.bb-1 {border-bottom: 1px solid currentColor;}

/* Form elements */
input, textarea {
  background: #fff;
  border: none;
  padding: .4em .8em;
  width: 100%;
  line-height: normal;
  outline: none;
  display: block;
  transition: $transition;
  &:-moz-placeholder, &::-moz-placeholder,
  &::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
    color: $achromat-3;
    font-weight: 400;
  }
  &::placeholder {
    color: $achromat-3;
    font-weight: 400;
  }
}
textarea {border-radius: $radius;}
input {
  border-radius: $radius*2;
  &[type='radio'] {
    @include no-appearance();
    cursor: pointer;
    width: 1em;
    height: 1em;
    background-color: #fff;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    transition: $transition;
    border: 2px solid $achromat-3;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60%;
      height: 60%;
      border-radius: 50%;
      background-color: $achromat-3;
      transform: translate(-50%, -50%);
      transition: $transition;
    }
    &:checked {
      border: 2px solid $color-1;
      &::after {
        background-color: $color-1;
      }
    }
  }
  &[type='checkbox'] {
    @include no-appearance();
    cursor: pointer;
    width: 1em;
    height: 1em;
    background-color: #fff;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    transition: $transition;
    border: 2px solid $achromat-3;
    border-radius: $radius*.2;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 80%;
      transform-origin: 50% 50%;
      transform: rotateZ(45deg) translate(-100%, -25%);
      border-bottom: 4px solid $color-1;
      border-right: 4px solid $color-1;
      border-radius: 2px;
      opacity: 0;
      transition: $transition;
    }
    &:checked {
      border: 2px solid $color-1;
      &::after {
        opacity: 1;
      }
    }
  }
}
label {
  @include flex(row, flex-start, center, nowrap);
  span {
    flex: 1;
    cursor: pointer;
  }
  &.pill {
    input {
      display: none;
      &:checked + span {
        background-color: $color-1;
        color: $achromat-1;
      }
    }
    span {
      background-color: $achromat-1;
      padding: .4em .8em;
      border-radius: $radius*2;
      transition: $transition;
      @media (max-width:575px) {
        border-radius: $radius;
      }
    }
  }
  &.figure {
    display: block;
    input {
      display: none;
      &:checked ~ .figure-img {
        background-image: linear-gradient(0deg, #4737FF, #4737FF), var(--img);
      }
      &:checked ~ .text {
        color: $color-1;
      }
    }
    .figure-img {
      width: 100%;
      max-width: 200px;
      height: 200px;
      border-radius: $radius;
      background-size: cover;
      transition: $transition;
      background-blend-mode: screen, normal;
      @media (max-width:991px) {
        height: 140px;
      }
    }
    .text {
      transition: $transition;
      margin-top: .5em;
    }
  }
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}
.btn {
  @include flex(row, center, center, nowrap);
  padding: .4em .8em;
  text-align: center;
  transition: $transition;
  width: fit-content;
  font-size: 1em;
  font-weight: 400;
  border-radius: $radius*2;
  &-1 {
    @extend .btn;
    color: #fff;
    background: $color-1;
    &:hover, &:focus, &:active, &.active {
      color: #fff;
      background: $color-1-dark;
    }
  }
  &-2 {
    @extend .btn;
    color: #fff;
    background: $color-2;
    &:hover, &:focus, &:active, &.active {
      color: #fff;
      background: $color-2-dark;
    }
  }
  &-3 {
    @extend .btn;
    color: $achromat-5;
    background: $achromat-1;
    &:hover, &:focus, &:active, &.active {
      color: $color-2;
      background: $achromat-2;
    }
  }
  &-4 {
    @extend .btn;
    color: $achromat-5;
    background: transparent;
    border: 1px solid $color-2;
    &:hover, &:focus, &:active, &.active {
      color: $achromat-5;
      background: $color-2;
      border: 1px solid $color-2;
    }
  }
}
/* forms */
.callback-2 {
  background-image: url(imgs/lines3.png), $gradient-1;
  background-repeat: no-repeat;
  background-size: auto 100%, cover;
  background-position: right center, center center;
  color: $achromat-1;
  padding: 1.5em;
  border-radius: $radius*1.25;
  font-size: .85em;
}
.callback {
  background-image: url(imgs/lines2.png), $gradient-1;
  background-repeat: no-repeat;
  background-size: contain, cover;
  background-position: right center, center center;
  color: $achromat-1;
  padding: 2em;
  border-radius: $radius*1.25;
  input {
    max-width: 425px;
    margin-right: .8em;
    @media (max-width:575px) {
      margin-right: 0px;
      margin-bottom: 1em;
    }
  }
  @media (max-width:767px) {
    padding: 1.5em;
  }
  @media (max-width:575px) {
    padding: 1em;
    button {width: 100%;}
  }
}


/* Blocks */
.flex-1 {flex:1;}
.mb-35 {
  margin-bottom: 3.5em;
  @media (max-width:575px) {
    margin-bottom: 2.5em;
  }
}

.page-title {
  width: 100%;
  min-height: 200px;
  height: fit-content;
  background-color: $color-1;
  color: $achromat-1;
  border-radius: $radius*1.5;
  margin-bottom: 2em;
  padding: 1.5em 2em;
  position: relative;
  overflow: hidden;
  @include safariFix();
  @include shadow();
  @media (max-width:1399px) {
    min-height: 180px;
  }
  @media (max-width:1199px) {
    min-height: 150px;
  }
  @media (max-width:991px) {
    min-height: 120px;
  }
  @media (max-width:767px) {
    min-height: 100px;
    padding: 1em 1.5em;
  }
  @media (max-width:575px) {
    min-height: 85px;
  }
  &::before {
    content: '';
    @include absBG();
    background: linear-gradient(to right, $color-1-dark -19.7%, $color-1 33.18%, transparent 100.76%);
    z-index: 2;
  }
  img {
    @include absBG();
    left: 10%;
    object-fit: cover;
    filter: saturate(0);
  }
  .text {
    position: relative;
    z-index: 3;
    width: 60%;
    @media (max-width:991px) {
      width: 80%;
    }
    @media (max-width:767px) {
      width: 100%;
    }
  }
  &-2 {
    @extend .page-title;
    min-height: 150px;
    @media (max-width:991px) {
      min-height: 120px;
    }
    @media (max-width:767px) {
      min-height: 100px;
    }
    @media (max-width:575px) {
      min-height: 85px;
    }
    h1 {
      font-size: 2em;
      @media (max-width:575px) {
        font-size: 1.5em;
      }
    }
    img {
      left: 5%;
      object-position: 50% 65%;
    }
    &::before {
      background: linear-gradient(to right, $color-2 30%, transparent 70%);
      @media (max-width:767px) {
        background: linear-gradient(to right, $color-2 40%, transparent 85%);
      }
      @media (max-width:575px) {
        background: linear-gradient(to right, $color-2 50%, transparent 100%);
      }
    }
  }
}
.breadcrumbs {
  color: $achromat-4;
  margin-bottom: 2em;
  ul {
    @include list();
    @include flex(row, flex-start, center, wrap);
    li {
      &:not(:first-child){
        margin-left: .5em;
        &::before {
          content: '>';
          margin-right: .5em;
        }
      }
    }
  }
}
.table-colored {
  border: 1px solid $color-1;
  border-radius: $radius;
  overflow: hidden;
  @include safariFix();
  @media (max-width:575px) {
    font-size: .9em;
  }
  table {
    width: 100%;
    thead {
      background-color: $color-1;
      color: $achromat-1;
      th {
        padding: .25em .5em;
        font-weight: 600;
        &:not(:first-child) {
          border-left: 1px solid $achromat-1;
        }
      }
    }
    tbody {
      tr {
        &:not(:first-child) {
          border-top: 1px solid $color-1;
        }
      }
      td {
        padding: .25em .5em;
        &:not(:first-child) {
          border-left: 1px solid $color-1;
        }
      }
    }
  }
}
.modal-xl {
  --bs-modal-width: 100%;
}
@media (min-width: 576px) {
  .modal-xl {
    --bs-modal-width: 540px;
  }
}
@media (min-width: 768px) {
  .modal-xl {
    --bs-modal-width: 720px;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    --bs-modal-width: 960px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}



body {
  margin: 0;
  font-family: $main-font, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  font-weight: 400;
  color: $achromat-5;
  overflow-x: hidden;
  position: relative;
  @media (max-width:991px) {
    padding: var(--h-height) 0px var(--f-height);
  }
}


header {
  // padding: 1em 0;
  // height: var(--h-height);
  &.mobile {
    height: var(--h-height);
    padding: 0;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1100;
    background-color: $achromat-2;
    border-radius: 0 0 $radius $radius;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.09);
    .container {
      @include flex(row, space-between, center, nowrap);
    }
    .menu-btn {
      font-size: 1.5em;
      display: flex;
      svg {
        path {
          transition: $transition;
          opacity: 1;
        }
        &.cross {
          path:first-child {
            transform: rotate(-43deg);
            transform-origin: top right;
          }
          path:nth-child(2) {
            opacity: 0;
          }
          path:last-child {
            transform-origin: bottom right;
            transform: rotate(43deg);
          }
        }
      }
    }
  }
  &.top {
    height: var(--h-top);
    .container {
      height: 100%;
      @include flex(row, space-between, center, nowrap);
      address {font-size: .75em;}
      ul {
        @include list();
        &.menu-1 {
          @include flex(row, flex-start, center, nowrap);
          li {
            &:not(:first-child){margin-left: 1em;}
            a {
              color: $achromat-4;
              transition: $transition;
              &:hover, &:focus, &:active, &.active {
                color: $color-1;
              }
            }
          }
        }
      }
    }
    @media (max-width:991px) {
      height: 100%;
    }
  }
  &.middle {
    height: var(--h-middle);
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    padding: 1em 0;
    background-color: $achromat-1;
    border-top: 1px solid $achromat-3;
    border-bottom: 1px solid $achromat-3;
    .container {
      height: 100%;
      @include flex(row, space-between, center, nowrap);
      .form-search {
        width: 390px;
        border: 1px solid $color-1;
        border-radius: $radius*2;
        position: relative;
        @media (max-width:1199px) {
          width: 260px;
        }
        button {
          color: $color-1;
          position: absolute;
          top: 0px;
          right: 0px;
          height: 100%;
          width: 2em;
        }
      }
    }
  }
  &.bottom {
    height: var(--h-bottom);
    .container {
      ul {
        @include list();
        &.menu-2 {
          @include flex(row, space-between, center, nowrap);
          a {
            color: $achromat-5;
            transition: $transition;
            &:hover, &:focus, &:active, &.active {
              color: $color-1;
            }
          }
        }
      }
    }
  }
}
.logo {
  @include flex(row, flex-start, flex-end, nowrap);
  img, svg {
    height: 60px;
    width: fit-content;
    margin-right: 0.5em;
    margin-bottom: 0.15em;
    @media (max-width:991px) {
      height: 33px;
    }
  }
}

main {
  min-height: calc(100vh - var(--h-height) - var(--f-height));
  padding: 2em 0;
  hr {
    height: 1px;
    background-color: $achromat-3;
    width: 100%;
    border: none;
    margin: 1em 0;
    opacity: 1;
  }
}

.swiper {
  &-button {
    &-prev, &-next {
      top: 50%;
      margin: 0px !important;
      transform: translateY(-50%);
      width: 1.25em;
      height: 1.25em;
      border-radius: 50%;
      background-color: rgba($color: $achromat-1, $alpha: .5);
      color: $achromat-1;
      @include flex(row, center, center, nowrap);
      &::after {content: unset;}
      svg {font-size: .75em;}
    }
    &-prev {left: .8em;}
    &-next {right: .8em;}
  }
  &-scrollbar {
    background-color: $achromat-3;
    height: 10px !important;
    &-drag {
      background-color: $achromat-4;
    }
  }
}

/* Section 1 */
.sec-1 {
  @include shadow();
  .swiper {
    position: relative;
    &-slide {
      height: 420px;
      background-color: #7297BB;
      border-radius: $radius;
      position: relative;
      overflow: hidden;
      @include safariFix();
      @media (max-width:991px) {
        height: 380px;
      }
      @media (max-width:575px) {
        height: 275px;
      }
      .img {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        z-index: 1;
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          z-index: 1;
          background-image: linear-gradient(to right, $color-3 30%, transparent 65%);
          background-size: cover;
          @media (max-width:991px) {
            background-image: linear-gradient(to right, $color-3 50%, transparent 85%);
          }
        }
        img {
          height: 100%;
          width: 70%;
          object-fit: cover;
          object-position: center;
          margin-left: auto;
          display: block;
          position: relative;
          z-index: 0;
          @media (max-width:991px){width: 50%;}
        }
      }
      .descr {
        position: relative;
        z-index: 2;
        color: #fff;
        padding: 2.5em 4em;
        height: 100%;
        width: 85%;
        @media (max-width:991px) {
          padding: 2em 3em;
          width: 100%;
        }
        @media (max-width:575px) {
          padding: 1em;
        }
        .title {
          font-size: 2em;
          font-weight: 700;
          @media (max-width:991px) {
            font-size: 1.75em;
          }
          @media (max-width:767px) {
            font-size: 1.5em;
          }
          @media (max-width:575px) {
            font-size: 1.25em;
          }
        }
      }
    }
    &-pagination {
      width: fit-content;
      height: fit-content;
      top: unset;
      bottom: 2.5em;
      left: 4em;
      @include flex(row, flex-start, center, nowrap);
      @media (max-width:991px) {
        bottom: 2em;
        left: 3em;
      }
      @media (max-width:575px) {
        bottom: 1em;
        left: 1em;
      }
      &-bullet {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin: 0 13px 0 0;
        background-color: #fff;
        opacity: .5;
        transition: $transition;
        &-active {
          opacity: 1;
          width: 50px;
        }
      }
    }
  }
}

/* Section 2 */
.sec-2 {
  .row {
    .col {
      @media (max-width:991px) {
        &:nth-child(6), &:nth-child(7){display: none;}
      }
      @media (max-width:767px) {
        &:nth-child(4), &:nth-child(5){display: none;}
      }
    }
  }
}
.category-card {
  img {
    width: 100%;
    height: var(--img-height);
    object-fit: cover;
    border-radius: $radius;
    @include shadow();
    margin-bottom: .5em;
  }
  &-btn {
    width: 100%;
    height: var(--img-height);
    background-image: url(imgs/lines.png), $gradient-1;
    background-repeat: no-repeat;
    background-position: 100% 100%, 50% 50%;
    border-radius: $radius;
    background-size: cover, cover;
    @include shadow();
    color: $achromat-1;
    @include flex(column, center, flex-start, nowrap);
    text-align: left;
    padding: 5% 20% 20% 15%;
    .icon {
      margin-top: .5em;
      width: 1.7em;
      height: 1.7em;
      font-size: 1.2em;
      background-color: $achromat-1;
      color: $color-1;
      border-radius: 50%;
      @include flex(row, center, center, nowrap);
      padding-left: 0.1em;
    }
  }
}

/* Section 3 */
.sec-3 {
  .swiper {
    &-wrapper{align-items: stretch;}
    &-slide {height: unset !important;}
    &-scrollbar {opacity: 0;}
    @media (max-width: 991px) {
      padding-bottom: 2em;
      .swiper-scrollbar {
        opacity: 1;
      }
    }
  }
  .offer {
    width: 100%;
    background-image: url(imgs/mask.png), $gradient-1;
    background-repeat: no-repeat;
    background-size: contain, cover;
    background-position: 100% 100%, 50% 50%;
    padding: 2.5em;
    border-radius: $radius;
    @include shadow();
    color: $achromat-1;
    @media (max-width:1199px) {
      padding: 2em;
    }
    @media (max-width: 991px) {
      height: 100%;
      @include flex(column, center, flex-start, nowrap);
    }
    @media (max-width:575px) {
      padding: 1em;
    }
    h3 {
      width: 90%;
    }
    p {
      width: 70%;
      margin: 1.5em 0;
      @media (max-width:575px) {
        margin: 1em 0;
      }
    }
  }
}
.project-card {
  position: relative;
  transition: $transition;
  &:hover {
    transform: translateY(-7%);
  }
  img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: var(--img-height);
    object-fit: cover;
    border-radius: $radius;
    @include shadow();
  }
  figcaption {
    padding: .8em .8em 0;
    @media (max-width:1199px) {
      padding: .8em 0 0;
    }
    h5 {
      font-weight: 400;
      @include clamp(2, 1.2em);
    }
  }
  .marking {
    background-color: $color-1;
    color: $achromat-1;
    border-radius: $radius*2;
    position: absolute;
    top: .8em;
    right: .8em;
    z-index: 2;
    font-size: .75em;
    padding: 0.5em 0.8em;
    @include shadow2();
  }
}

/* Section 4 */
#map {
  @include shadow2();
  width: 100%;
  margin-bottom: 1em;
  border-radius: $radius;
  height: 400px;
  @media (max-width:767px) {
    height: 320px;
  }
  @media (max-width:575px) {
    height: 75vw;
  }
}

/* Section 5 */
article {
  margin-bottom: 2em;
  @media (max-width:575px) {
    font-size: .8em;
  }
  img {
    width: 100%;
    height: var(--img-height);
    border-radius: $radius*1.5;
  }
  &.news {
    p {@include clamp(4, 1.2em);}
  }
  &.pf {
    p {@include clamp(2, 1.2em);}
  }
  ul {padding-left: 1.5em;}
  .btn-2 {
    margin-top: 2em;
    @media (max-width:991px) {
      margin-top: 1em;
    }
  }
}

/* Section 6 */
.sec-6 {
  .img {
    float: right;
    margin-bottom: 1em;
    margin-left: 1em;
    width: 48%;
    border-radius: $radius;
  }
  p {
    line-height: 120%;
    margin-bottom: 1.5em;
  }
}

/* Section 7 */
.sec-7 {
  .img {
    width: 100%;
    height: var(--img-height-4);
    object-fit: cover;
    border-radius: $radius;
  }
  .icon {
    width: 120px;
    height: 100px;
    object-fit: contain;
    margin-bottom: .5em;
  }
}

/* Section 9 */
.sec-9 {
  .swiper {
    position: unset;
    img {
      width: 100%;
      height: 460px;
      object-fit: cover;
      border-radius: $radius;
      @media (max-width:1399px) {
        height: 380px;
      }
      @media (max-width:1199px) {
        height: 310px;
      }
      @media (max-width:991px) {
        height: 220px;
      }
      @media (max-width:767px) {
        height: 315px;
      }
      @media (max-width:575px) {
        height: 65vw;
      }
    }
    &-button {
      &-prev, &-next {background-color:$achromat-3}
      &-prev {left: 0em;}
      &-next {right: 0em;}
    }
  }
  p {margin-bottom: 1em;}
  hr {
    background-color: $achromat-3;
    width: 100%;
    height: 1px;
    border: none;
    margin: 1em 0;
    opacity: 1;
  }
}

/* Section 10 */
.sec-10 {
  .img {
    width: 100%;
    height: 190px;
    object-fit: cover;
    border-radius: $radius*1.5;
    @media (max-width: 1399px) {
      height: 160px;
    }
    @media (max-width: 1199px) {
      height: 135px;
    }
    @media (max-width: 991px) {
      height: 215px;
    }
    @media (max-width: 767px) {
      height: 155px;
    }
    @media (max-width: 575px) {
      height: 29vw;
    }
  }
}

/* Section 11 */
.sec-11 {
  .img {
    width: 100%;
    height: var(--img-height-4);
    object-fit: cover;
    border-radius: $radius*1.25;
  }
}
.service {
  img {
    width: 100%;
    height: var(--img-height-4);
    object-fit: cover;
    border-radius: $radius;
    margin-bottom: .5em;
  }
  h4 {
    font-weight: 600;
    margin-bottom: 1em;
    @media (max-width:575px) {
      margin-bottom: .5em;
    }
  }
  .btn-2 {
    margin-top: 1.5em;
    @media (max-width:575px) {
      margin-top: .5em;
    }
  }
  @media (max-width:575px) {
    p{font-size: .8em;}
  }
}

/* Section 12 */
.sec-12 {
  .main-img {
    width: 100%;
    height: 465px;
    object-fit: cover;
    border-radius: $radius;
    @include shadow();
    @media (max-width:1399px) {
      height: 400px;
    }
    @media (max-width:1199px) {
      height: 350px;
    }
    @media (max-width:991px) {
      height: 500px;
    }
    @media (max-width:767px) {
      height: 365px;
    }
    @media (max-width:575px) {
      height: 65vw;
    }
  }
  .swiper {
    padding-top: 1em;
    padding-bottom: 2em;
    img {
      width: 100%;
      height: 290px;
      object-fit: cover;
      border-radius: $radius;
      @include shadow();
      @media (max-width:1399px) {
        height: 255px;
      }
      @media (max-width:1199px) {
        height: 215px;
      }
      @media (max-width:991px) {
        height: 300px;
      }
      @media (max-width:767px) {
        height: 245px;
      }
      @media (max-width:575px) {
        height: 42vw;
      }
    }
  }
}


.quiz {
  background-color: $achromat-2;
  padding: 2em;
  border-radius: $radius;
  @include shadow();
  @media (max-width:1199px) {
    padding: 1.5em;
  }
  @media (max-width:575px) {
    padding: 1em;
  }
  @media (max-width:370px) {
    font-size: .9em;
  }
  .img {
    width: 100%;
    height: 365px;
    object-fit: cover;
    border-radius: $radius;
    @media (max-width:1399px) {
      height: 325px;
    }
    @media (max-width:1199px) {
      height: 280px;
    }
    @media (max-width:991px) {
      height: 240px;
    }
  }
  .markers {
    @include flex(row, flex-start, center, nowrap);
    margin-top: .5em;
    margin-bottom: 1em;
    span {
      width: .4em;
      height: .4em;
      border-radius: .2em;
      background-color: $color-2;
      margin-right: .65em;
      transition: $transition;
      opacity: .5;
      &.active {
        width: 2em;
        opacity: 1;
      }
      @media (max-width: 767px) {
        margin-right: .4em;
        &.active {width: 1em;}
      }
      @media (max-width: 575px) {
        margin-right: .2em;
      }
    }
  }
  ul {
    @include list();
    li {
      &:not(:first-child){margin-top: .75em;}
    }
  }
}

footer {
  height: var(--f-height);
  background-color: $achromat-5;
  color: $achromat-1;
  padding: 2em 0;
  font-weight: 300;
  &.mobile {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1100;
    filter: drop-shadow(0px -3px 5px rgba(0, 0, 0, 0.09));
    background-color: $achromat-2;
    color: $achromat-5;
    padding: 0;
    nav {
      ul {
        @include flex(row, space-between, center, nowrap);
        li {
          margin-top: 0px !important;
          a, button {
            color: $achromat-5;
            @include flex(column, center, center, nowrap);
            border-bottom: none;
            position: relative;
            padding-bottom: .3em;
            &::after {
              content: '';
              position: absolute;
              bottom: 0px;
              left: 0px;
              width: 0%;
              height: 2px;
              border-radius: 2px;
              background-color: currentColor;
              transition: $transition;
            }
            &.active {
              &::after {width: 100%;}
              svg {
                color: $achromat-5;
              }
            }
            svg {
              font-size: 1.5em;
              color: $achromat-4;
              margin-bottom: .2em;
            }
          }
        }
      }
    }
  }
  ul {
    @include list();
    li {
      &:not(:first-child){margin-top: .5em;}
      a {
        border-bottom: 1px solid currentColor;
        transition: $transition;
        @media (min-width: 992px) {
          &:hover, &:focus, &:active {
            color: $color-2;
          }
        }
      }
    }
  }
  .bottom {
    @include flex(row, flex-start, center, nowrap);
    margin-top: 2em;
  }
}

.offcanvas {
  &.offcanvas-bottom {
    height: 100vh;
  }
  &.offcanvas-end {
    width: 50%;
    @media (max-width:767px) {
      width: 75%;
    }
    @media (max-width:575px) {
      width: 100%;
    }
  }
  &-body {
    padding: var(--h-height) 0 var(--f-height);
    background-image: linear-gradient(to bottom, transparent 0%, $achromat-2 100%);
    .box {
      padding: 1em;
    }
  }
  .img {
    width: 100%;
    height: 20vw;
    object-fit: cover;
    @include shadow2();
    border-radius: $radius;
    @media (max-width:767px) {
      height: 28vw;
    }
    @media (max-width:575px) {
      height: 38vw;
    }
  }
  nav {
    ul {
      @include list();
      &:not(:last-child){margin-bottom: 1em;}
      li {
        &:not(:last-child){margin-bottom: .15em;}
        a, button {
          @include flex(row, flex-start, center, nowrap);
          @include shadow3();
          background-color: $achromat-1;
          padding: 1em;
          span {flex: 1;}
        }
      }
    }
  }
}

.modal {
  background-color: transparent;
  .modal-content {
    background-color: transparent;
    border: none;
    padding-top: 3em;
    .modal-body{
      padding: 0px;
      position: relative;
      .close {
        position: absolute;
        top: 1em;
        right: 1em;
        font-size: 1.5em;
        color: red;
      }
      img {
        display: block;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}